<template>
  <div class="speedcheck">
    <LoadingPlaceholder v-if="preparing == true" />

    <SessionOverview
      v-show="preparing == false"
      v-if="runs != null && filter.startDate != null"
      :display-properties="['speed', 'createDate', 'card']"
      :runs="runs"
      :loading="loading"
      :filter="filter"
      :installation-type="installationType"
      :is-china-installation="isChinaInstallation"
      :installation-id="installationId"
      @loadSessions="loadSessions"
    />

    <SweetModal
      ref="speedcheckModal"
      title="Session"
      class="overflowHidden"
      @close="closeModal"
    >
      <template
        slot="box-action"
      >
        <toggle-button
          v-if="!isChinaInstallation"
          v-model="viewDecoratedPhotos"
          :labels="{ checked: 'decorated', unchecked: 'normal' }"
          :width="100"
          :height="30"
          :font-size="12"
          class="mr-3 mt-2"
        />
      </template>
      <sweet-modal-tab
        id="tab1"
        title="Photo"
      >
        <img
          v-if="altImageAvailable"
          id="img"
          :src="altImageUrl"
          class="img-fluid"
        >
        <ImagePlaceholderSvg
          v-else
          class="img-fluid"
        />
      </sweet-modal-tab>

      <sweet-modal-tab
        id="tab2"
        title="Details"
      >
        <table
          v-if="payload != null"
          class="defaultTable"
        >
          <colgroup>
            <col width="200">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>Id</th>
              <td class="lineBreaks">
                {{ payload.id }}
              </td>
            </tr>
            <tr>
              <th>Card Number</th>
              <td class="lineBreaks">
                {{ payload.identificationId }}
              </td>
            </tr>
            <tr>
              <th>Create date</th>
              <td v-tooltip="dateTime_fromNow(payload.created)">
                {{ dateTime_dateTime(payload.created) }}
              </td>
            </tr>
            <tr>
              <th>Image Url</th>
              <td>{{ payload.imageUrl }}</td>
            </tr>
            <tr>
              <th>Snapshot Url</th>
              <td>{{ payload.snapshotUrl }}</td>
            </tr>
            <tr>
              <th>Speed</th>
              <td>{{ payload.speed }}</td>
            </tr>
          </tbody>
        </table>
      </sweet-modal-tab>
      <sweet-modal-tab
        id="tab3"
        title="EXIF"
      >
        <pre v-if="exifData">{{ exifData }}</pre>
        <template v-else>
          {{ $t('noDataAvailable') }}
        </template>
      </sweet-modal-tab>
    </SweetModal>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { SweetModal, SweetModalTab } from 'sweet-modal-vue';

export default {
  name: "Speedcheck",
  components: {
    ImagePlaceholderSvg: () => import('@/components/Base/ImagePlaceholderSvg.vue'),
    SessionOverview: () => import('@/components/Media/SessionOverview.vue'),
    SweetModal,
    SweetModalTab
  },
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    installationType: {
      type: String,
      required: true
    },
    installationCountry: {
      type: String,
      required: false,
      default: ""
    }
  },
  data () {
    return {
      useAlternativeFilter: true,
      loading: true,
      runs: null,
      altImageUrl: "",
      modalId: "speedcheckModal",
      filter: {
        startDate: null,
        endDate: null,
        cardNumber: null,
        showIncomplete: true,
        limit: 100
      },
      preparing: true,
      payload: null,
      viewDecoratedPhotos: true,
      exifData: null
    }
  },
  computed: {
    isChinaInstallation () {
      return this.installationCountry == 'China';
    },
    altImageAvailable: function () {
      if (this.payload && this.altImageUrl && this.altImageUrl.length > 0) {
        return true;
      }
      return false;
    }
  },
   watch: {
    viewDecoratedPhotos (val) {
      if(this.altImageAvailable) {
        this.altImageUrl = val ? this.decoratedUrl(this.payload.id) : this.payload.imageUrl;
      }
    }
  },
  created () {
    let tmpDate = new Date(Date.now());
    this.filter.startDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate() - 1, 0, 0, 0);
    this.filter.endDate = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), 23, 59, 59);
    this.filter.dates = [];
    this.filter.dates.push(this.filter.startDate, this.filter.endDate);

    this.loadSessions();
    this.$eventBus.$on(`openModal${this.modalId}`, this.openModal);
  },
  beforeDestroy () {
    this.$eventBus.$off(`openModal${this.modalId}`, this.openModal);
  },
  methods: {
     decoratedUrl (runId) {
      return "https://api.skiline.cc/php/app/api-helper/0.10/Admin/Video/decoratedRun.php?runId="+runId+"&apiKey=383zhdd7yJHDOcvmiq563wnmdjuhJ83wdsn";
    },
    isValidVideo (imageUrl, snapshotUrl) {
      if (imageUrl && snapshotUrl) return true;
      return false;
    },
    loadSessions (payload) {
      if (payload == null || this.useAlternativeFilter == true) {
        payload = {
          limit: 100,
          dates: [this.useAlternativeFilter == true ? null : this.filter.startDate, this.useAlternativeFilter == true ? null : this.filter.endDate]
        }
      }
      this.loading = true;
      this.axios.get(`/Media/GetSpeedcheck/${ this.installationId }/${ payload.limit }/${ this.dateTime_isoString(payload.dates[0]) }/${ this.dateTime_isoString(payload.dates[1]) }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.runs = response.data;
        })
        .finally(() => {
          this.loading = false;
          window.setTimeout(() => {
            this.preparing = false;
          }, 500);
        });
    },
    getExifData (payload) {
      if(payload.imageUrl) {
        var urlObj = {
          url: payload.imageUrl
        };

        this.axios.post(`/Media/GetExifData`, urlObj)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.exifData = JSON.stringify(JSON.parse(response.data),null,2);
        });
      }
    },
    // toggle the modal-element (the lightbox)
    openModal (payload) {
      if(this.isChinaInstallation) {
        this.viewDecoratedPhotos = false;
      }

      if (payload) {
        this.getExifData(payload);
        this.payload = payload;
        if(this.viewDecoratedPhotos) {
          this.altImageUrl = this.decoratedUrl(payload.id);
        } 
        else {
          this.altImageUrl = payload.imageUrl;
        }
      }
      this.$refs.speedcheckModal.open();
    },
    closeModal () {
      this.payload = null;
      this.altImageUrl = '';
      this.exifData = null;
    },
  }
}
</script>

<style scoped>
input[disabled]{
  cursor: default;
}
.modal-body video,
.modal-body .row .col {
  margin-bottom: 1rem;
}
</style>
